"use strict";
/*	This file is part of MuWidget.

    MuWidget is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    MuWidget is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with MuWidget.  If not, see <https://www.gnu.org/licenses/>. */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetAttributes = exports.MuWidget = void 0;
const MuBinder_1 = require("./MuBinder");
const JSONS_1 = require("./utils/JSONS");
// export class MuWidget<TP extends MuWidget = MuWidget<any,{},{}>, TU extends Record<string, any&AnyElement> = {}, TW extends Record<string, any&AnyElement> = {}> {
class MuWidget {
    muWidgetFromTemplate(templateName, container, params = null, position = "last", ref = null) {
        const finalContainer = this.muGetContainer(container);
        var tmpElemementType = "div";
        let templateContent;
        if (typeof templateName === "string") {
            templateContent = this.muFindTemplate(templateName);
            if (!templateContent)
                throw "No template named '" + templateName + "'.";
        }
        else {
            templateContent = templateName.html;
        }
        var tmpTemplate = templateContent;
        tmpTemplate = tmpTemplate.toLowerCase();
        if (tmpTemplate.startsWith('<tr'))
            tmpElemementType = "tbody";
        if (tmpTemplate.startsWith('<td') || tmpTemplate.startsWith('<th'))
            tmpElemementType = "tr";
        if (tmpTemplate.startsWith('<tbody') || tmpTemplate.startsWith('<thead') || tmpTemplate.startsWith('<tfoot'))
            tmpElemementType = "table";
        var element = document.createElementNS((finalContainer || this.container).namespaceURI, tmpElemementType);
        element.innerHTML = templateContent;
        element = element.firstElementChild;
        // const element = this.createElementFromHTML(templateContent, container || this.container);
        // if (params) element.setAttribute('mu-params', JSON.stringify(params));
        if (finalContainer)
            this.muPlaceElement(element, finalContainer, position, ref);
        let widget = this.muActivateWidget(element, null, params || {}, typeof templateName === "string" ? null : templateName);
        let opts = this.muGetElementOpts(element);
        if (!opts.id)
            opts.id = (typeof templateName === "string") ? templateName : null;
        this.muAddEvents(opts, element, widget);
        return widget;
    }
    muGetContainer(container) {
        let finalContainer = null;
        if (typeof container == 'string') {
            var containerName = container;
            finalContainer = this.ui[container];
            if (!finalContainer)
                throw new Error("Container with mu-id='" + containerName + "' not exists.");
        }
        else
            finalContainer = container;
        return finalContainer;
    }
    muPlaceElement(element, finalContainer, position = "last", ref = null) {
        switch (position) {
            case 'first':
                if (finalContainer.firstElementChild) {
                    finalContainer.insertBefore(element, finalContainer.firstElementChild);
                }
                else {
                    finalContainer.appendChild(element);
                }
                break;
            case 'before':
                finalContainer.insertBefore(element, ref);
                break;
            case 'after':
                if (ref.nextElementSibling) {
                    finalContainer.insertBefore(element, ref.nextElementSibling);
                }
                else {
                    finalContainer.appendChild(element);
                }
                break;
            case 'last':
                finalContainer.appendChild(element);
                break;
        }
    }
    muCreateWidget(widgetName, container, params = null, elementName = 'div', position = "last", ref = null) {
        const finalContainer = this.muGetContainer(container);
        const element = document.createElementNS(finalContainer.namespaceURI, elementName);
        element.setAttribute("mu-widget", widgetName);
        this.muPlaceElement(element, finalContainer, position, ref);
        // @ts-ignore
        const cWidget = new MuWidget(finalContainer);
        const widget = cWidget.muActivateWidget(
        // @ts-ignore
        element, {
            widget: widgetName,
        }, Object.assign({ muParent: this }, params));
        return widget;
    }
    muAppendContent(html) {
        for (const node of this.createNodeArrayFromHTML(html, this.container)) {
            this.container.appendChild(node);
        }
        // this.container.innerHTML += html;
    }
    createNodeArrayFromHTML(src, container) {
        let lSrc = src.toLowerCase();
        let tmpElemementType = "div";
        if (lSrc.startsWith('<tr'))
            tmpElemementType = "tbody";
        if (lSrc.startsWith('<td') || lSrc.startsWith('<th'))
            tmpElemementType = "tr";
        if (lSrc.startsWith('<tbody') || lSrc.startsWith('<thead') || lSrc.startsWith('<tfoot'))
            tmpElemementType = "table";
        let element = document.createElementNS(container.namespaceURI, tmpElemementType);
        element.innerHTML = src;
        return Array.from(element.childNodes);
    }
    createElementFromHTML(src, container) {
        return this.createNodeArrayFromHTML(src, container).find(item => item instanceof Element);
    }
    muRemoveSelf() {
        if (this.container.parentNode)
            this.container.parentNode.removeChild(this.container);
    }
    muGetChildWidgets(container) {
        return MuWidget.getChildWidgets((typeof container === "string")
            ? this.ui[container]
            : container);
    }
    muBindList(list, templateName, container, commonParams = {}, finalCalback = null) {
        var res = [];
        for (const item of list) {
            var params = Object.assign(Object.assign({}, item), (typeof commonParams === "function" ? commonParams(item) : commonParams));
            var widget = this.muWidgetFromTemplate(templateName, container, params);
            if (finalCalback)
                finalCalback(widget, item);
            res.push(widget);
        }
        return res;
    }
    muVisible(state, control) {
        if (Array.isArray(control)) {
            for (const controlItem of control)
                this.muVisible(state, controlItem);
        }
        else {
            let neg = false;
            if (typeof control === 'string') {
                if (control.startsWith('!')) {
                    neg = true;
                    control = control.substring(1);
                }
                if (control === ".")
                    control = this.container;
                else if (!(control in this.ui))
                    throw new Error("Control with mu-id='" + control + "' not found.");
                else
                    control = this.ui[control];
            }
            //@ts-ignore
            if (state === "toggle")
                state = control.style.display === "none";
            //@ts-ignore
            control.style.display = state !== neg ? null : "none";
        }
    }
    muBindData(srcData) {
        //@ts-ignore
        MuBinder_1.MuBinder.bindData(this.muBindOpts, srcData, this);
        this.muAfterBindData(srcData);
    }
    muFetchData() {
        //@ts-ignore
        return MuBinder_1.MuBinder.fetchData(this.muBindOpts, this);
    }
    // protected muRegisterEvent(...args) { }
    // public addEventListener(name : string, handler : (...args)=>void) { }
    // public muEventNames() : string[] { return []; }
    muAfterBindData(data) { }
    constructor(container) {
        this.ui = {}; //Record<string, AnyElementA> = {};
        this.muOpts = {};
        this.muWidgetEventHandlers = {};
        this.muIndexOpts = null;
        this.muSubWidgets = [];
        this.muNamedWidget = {}; // Record<string, MuWidget> = {};
        //@ts-ignore
        this.muRoot = this;
        this.muTemplates = {};
        this.muTemplateParents = {};
        this.muOnAfterIndex = [];
        this.muBindOpts = {};
        this.container = container;
    }
    // public constructor(container : AnyElement)
    muInit(container) {
        this.muOnAfterIndex = [];
        this.ui = {};
        this.muOpts =
            {
                attributePrefix: "mu-",
                bindEvents: [
                    'click', 'dblclick', 'mouseenter', 'mouseleave', 'mousemove', 'mouseout', 'mouseover', 'mouseup', 'blur',
                    'change', 'focus', 'select', 'submit', 'keyup', 'keydown', 'keypress', 'scroll',
                    'drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop', 'input',
                    'touchstart', 'touchmove', 'touchend', 'touchcancel',
                ],
                autoMethodNameSeparator: "_"
            };
        /*	opts ? opts : {}
        );*/
        this.container = container;
        //@ts-ignore
        this.container.widget = this;
        this.muSubWidgets = [];
        this.muNamedWidget = {};
        this.muTemplates = {};
        this.muTemplateParents = {};
        //@ts-ignore
        this.muRoot = this;
        this.muWidgetEventHandlers = {};
        this.beforeIndex();
        this.muAddEvents({ id: 'container' }, this.container);
        this.muIndexTree(this.container, true);
    }
    beforeIndex() { }
    muDispatchEvent(name, ...args) {
        if (!(name in this.muWidgetEventHandlers))
            throw new Error("Unknown event '" + name + "' on class '" + this.constructor.name + "'.");
        if (this.muWidgetEventHandlers[name]) {
            // const args = Array.from(arguments);
            // args[0] = <MuEvent>{
            const ev = {
                sender: this,
                originalEvent: event,
                args: Array.from(arguments).slice(1)
            };
            //console.log(args, arguments);
            /* for(const handler of this.muWidgetEventHandlers[name])
                handler.apply(this, args); */
            for (let i = 0, l = this.muWidgetEventHandlers[name].length; i < l; i++) {
                const handler = this.muWidgetEventHandlers[name][i];
                handler.call(this, ev);
            }
        }
    }
    muRegisterEvent(...args) {
        // for(var i = 0; i < arguments.length; i++) this.muWidgetEventHandlers[arguments[i]] = [];
        for (const eventName of args)
            this.muWidgetEventHandlers[eventName] = [];
    }
    addEventListener(name, handler) {
        if (!(name in this.muWidgetEventHandlers))
            throw new Error("Unknown event '" + name + "' on class '" + this.constructor.name + "'.");
        this.muWidgetEventHandlers[name].push(handler);
    }
    muEventNames() {
        return Object.keys(this.muWidgetEventHandlers);
    }
    muActivateWidget(element, opts, extraParams = {}, widgetDef = null) {
        if (!opts)
            opts = this.muGetElementOpts(element);
        const widgetName = opts.widget;
        let widget;
        if (widgetDef) {
            if (widgetDef.classInstance) {
                widget = widgetDef.classInstance;
                widget.container = element;
            }
            else if (widgetDef.classType) {
                widget = new widgetDef.classType(element, opts);
            }
        }
        let c = null;
        if (!widget) {
            c = MuWidget.widgetClasses[widgetName] || window[widgetName];
            if (!c)
                throw "Class '" + opts.widget + "' is not defined.";
            widget = new c(element, opts);
        }
        if (!(widget instanceof MuWidget)) {
            if (MuWidget.fixOldWidgets) {
                if (MuWidget.fixOldWidgets !== "silent")
                    console.error("Widget '" + widgetName + "' is not a descendant of the MuWidget class.");
                // extends prototype, class.prototype can not be enumarated
                //@ts-ignore
                if (c === null)
                    c = { prototype: widget.__proto__ };
                for (var k of [
                    'addEventListener', 'addEventListener(name, handler)', 'beforeIndex', 'createElementFromHTML', 'muActivateWidget', 'muAddEvent', 'muAddEvents',
                    'muAddUi', 'muAfterBindData', 'muBindData', 'muBindList', 'muCallPlugin', 'muDispatchEvent', 'muEventNames', 'muFetchData', 'muFindMethod',
                    'muFindTemplate', 'muGetChildWidgets', 'muGetElementOpts', 'muGetMethodCallback', 'muIndexTree', 'muInit', 'muRegisterEvent', 'muRemoveSelf',
                    'muVisible', 'muWidgetFromTemplate', 'muGetRoot'
                ])
                    if (!c.prototype[k])
                        c.prototype[k] = MuWidget.prototype[k];
                c.prototype.muIndexForm = function (form) {
                    if (!form) {
                        if (this.container.tagName == 'FORM')
                            form = this.container;
                        else
                            form = this.container.querySelector('form');
                    }
                    if (form) {
                        this.muAddUi('form', form);
                        this.muAddEvents({ id: 'form' }, form);
                        for (var i = 0, l = form.elements.length; i < l; i++) {
                            var element = form.elements[i];
                            this.muAddUi(element.name, element);
                            this.muAddEvents({ id: element.name }, element);
                        }
                    }
                };
            }
            else
                throw "Widget '" + widgetName + "' is not a descendant of the MuWidget class.";
        }
        widget.muParent = this;
        //@ts-ignore
        widget.muRoot = this.muRoot || this;
        if (opts.params) {
            const params = typeof opts.params === "string"
                ? (MuWidget.paramJsonS
                    ? JSONS_1.JSONS.parse(opts.params)
                    : JSON.parse(opts.params))
                : opts.params;
            for (const k in params) {
                widget[k] = params[k];
            }
        }
        if (extraParams) {
            for (const k in extraParams) {
                widget[k] = extraParams[k];
            }
        }
        // MuWidget.extendPrototype(widget);
        this.muSubWidgets.push(widget);
        //@ts-ignore
        if (opts.id)
            this.muNamedWidget[opts.id] = widget;
        // MuWidget.call(widget, element, /*opts.opts || this.muOpts */);
        widget.muInit(element);
        return widget;
    }
    muGetElementOpts(element) {
        var res = {};
        // Convert simplified syntax id:widget@template
        const simpleAttributeName = this.muOpts.attributePrefix.substring(0, this.muOpts.attributePrefix.length - 1);
        const simpleAttributeValue = element.getAttribute(simpleAttributeName);
        const ire = MuWidget.identifierRe;
        if (simpleAttributeValue) {
            const m = simpleAttributeValue.match('(' + ire + ')? *(:(' + ire + '))? *(@(' + ire + '))? *(#(.*))?');
            if (m) {
                if (m[1])
                    element.setAttribute(this.muOpts.attributePrefix + 'id', m[1]);
                if (m[3])
                    element.setAttribute(this.muOpts.attributePrefix + 'widget', m[3]);
                if (m[5])
                    element.setAttribute(this.muOpts.attributePrefix + 'template', m[5]);
                if (m[7])
                    element.setAttribute(this.muOpts.attributePrefix + 'bind', m[7]);
                element.removeAttribute(simpleAttributeName);
            }
        }
        // other mu- parameters
        for (var i = 0, attributes = element.attributes, n = attributes.length, arr = []; i < n; i++) {
            var name = attributes[i].nodeName;
            if (name.startsWith(this.muOpts.attributePrefix)) {
                var optName = name.substr(this.muOpts.attributePrefix.length);
                res[optName] = attributes[i].nodeValue;
            }
        }
        return res;
    }
    muFindTemplate(templateName) {
        let tmpTemplate = null;
        if (templateName.startsWith("shared:")) {
            let aTemplateName = templateName.substr(7);
            tmpTemplate = MuWidget.sharedTemplates[aTemplateName];
        }
        else if (templateName.startsWith("ancestor:")) {
            let aTemplateName = templateName.substr(9);
            //@ts-ignore
            let w = this;
            while (w) {
                if (w.muTemplates[aTemplateName]) {
                    tmpTemplate = w.muTemplates[aTemplateName];
                    break;
                }
                w = w.muParent;
            }
        }
        else {
            tmpTemplate = this.muTemplates[templateName];
        }
        return tmpTemplate;
    }
    muIndexTree(element, indexWidget, useName = null, addToUi = true) {
        var _a;
        //@ts-ignore
        var ev = { element: element, widget: this, opts: this.muGetElementOpts(element) };
        if (indexWidget) {
            this.muMetaData = {
                id: ev.opts.id,
                widget: this.constructor.name,
            };
        }
        //TODO: MuBinder
        // this.muCallPlugin("indexPrepareElement", ev);
        if (!ev.element)
            return;
        element = ev.element;
        var opts = ev.opts;
        if (opts.usetemplate) {
            var src = this.muFindTemplate(opts.usetemplate);
            // element.outerHTML = src;
            if (typeof src === "undefined")
                throw new Error("Template '" + opts.usetemplate + "' not exists.");
            var newElement = this.createElementFromHTML(src, element.parentNode);
            element.parentNode.insertBefore(newElement, element);
            element.parentNode.removeChild(element);
            element = newElement;
            if (opts.id)
                element.setAttribute(this.muOpts.attributePrefix + "id", opts.id);
            if (opts.params)
                element.setAttribute(this.muOpts.attributePrefix + "params", opts.params);
            if (opts.widget)
                element.setAttribute(this.muOpts.attributePrefix + "widget", opts.widget);
            if (opts.template)
                element.setAttribute(this.muOpts.attributePrefix + "template", opts.template);
            opts = this.muGetElementOpts(element);
        }
        if (opts.preproc) {
            this.preproc(element, opts.preproc);
        }
        this.muIndexOpts = opts;
        useName = useName || opts.usename;
        if (useName) {
            const n = (_a = element.attributes.getNamedItem("name")) === null || _a === void 0 ? void 0 : _a.value;
            if (n)
                opts.id = n;
        }
        if (opts.noindex)
            return;
        if (opts.template) {
            element.removeAttribute(this.muOpts.attributePrefix + "template");
            if (opts.template.startsWith("shared:")) {
                const name = opts.template.substring(7);
                if (name in MuWidget.sharedTemplates)
                    console.error("The widget already contains template '" + opts.template + "'.");
                MuWidget.sharedTemplates[name] = element.outerHTML;
            }
            else {
                if (opts.template in this.muTemplates)
                    console.error("The widget already contains template '" + opts.template + "'.");
                this.muTemplates[opts.template] = element.outerHTML;
                this.muTemplateParents[opts.template] = element.parentNode;
            }
            if (element.parentNode)
                element.parentNode.removeChild(element);
            return;
        }
        if (opts.id && element != this.container && addToUi)
            this.muAddUi(opts.id, element);
        // if (opts.bind) this.muParseBinds(opts.bind, element);
        ev.opts = opts;
        this.muCallPlugin("beforeIndexElement", ev);
        if ((!opts.widget || indexWidget) && !opts.nocontent && element.children) {
            // Index children
            var elements = [];
            // Create copy, template modify children
            for (const el of element.children)
                elements.push(el);
            for (const el of elements) {
                // if (elements[i])
                this.muIndexTree(el, false, useName);
            }
        }
        var widget = null;
        if (opts.widget && !indexWidget) {
            // Initialize widget
            widget = this.muActivateWidget(element, opts);
        }
        this.muAddEvents(opts, element, widget);
        if (indexWidget) {
            this.afterIndex();
            for (let i = 0, l = this.muOnAfterIndex.length; i < l; i++) {
                this.muOnAfterIndex[i](this);
            }
        }
        this.muCallPlugin("afterIndexElement", ev);
    }
    muAddUi(id, element) {
        if (id in this.ui)
            console.error("The widget '" + /*this.muGetIdentification()*/ this.muIndexOpts.widget + "#" + this.muIndexOpts.id + "' already contains an element with mu-id '" + id + "'.");
        //@ts-ignore
        this.ui[id] = element;
    }
    /* muGetIdentification() {
        return this.muIndexOpts.widget + (this.muIndexOpts.id ? "#" + this.muIndexOpts.id : "");
    } */
    muCallPlugin(eventName, eventArgs) {
        for (var plugin of MuWidget.plugIns) {
            if (plugin[eventName])
                plugin[eventName](eventArgs);
        }
    }
    afterIndex() { }
    preproc(element, preproc) {
        var p = preproc.indexOf(" ");
        var name = preproc;
        var paramsStr = "";
        if (p >= 0) {
            var name = preproc.substr(0, p);
            var paramsStr = preproc.substr(p);
        }
        if (!(name in MuWidget.preproc))
            throw new Error("Preproc '" + name + '" not defined.');
        var pp = MuWidget.preproc[name];
        const mode = pp.prototype.preproc ? "class" : "function";
        if (mode === "function")
            paramsStr = "[" + paramsStr + "]";
        else
            paramsStr = "{" + paramsStr + "}";
        try {
            var params = paramsStr
                ? (MuWidget.paramJsonS
                    ? JSONS_1.JSONS.parse(paramsStr)
                    : JSON.parse(paramsStr))
                : null;
        }
        catch (exc) {
            throw new Error(exc.toString() + "\n" + paramsStr);
        }
        if (mode === "function") {
            params.unshift(element);
            pp.call(null, params);
        }
        else {
            var inst = new pp();
            for (var k in params)
                inst[k] = params[k];
            inst.preproc(element);
        }
    }
    muAddEvents(opts, element, widget = null) {
        var autoMethodName;
        var eventNames = [...this.muOpts.bindEvents];
        var wEvents = [];
        if (widget) {
            wEvents = widget.muEventNames();
            eventNames = [...new Set([...eventNames, ...wEvents])];
        }
        var tags = opts.tag ? opts.tag.split(" ") : null;
        for (var i = 0, l = eventNames.length; i < l; i++) {
            const eventName = eventNames[i];
            const eventTarget = (widget && wEvents.indexOf(eventName) >= 0) ? widget : element;
            let methodName = opts[eventName];
            if (methodName === undefined) {
                if (opts.id) {
                    autoMethodName = opts.id + this.muOpts.autoMethodNameSeparator + eventName;
                    if (autoMethodName in this)
                        methodName = autoMethodName;
                }
            }
            if (methodName) {
                this.muAddEvent(eventName, eventTarget, this.muGetMethodCallback(methodName));
            }
            if (tags) {
                for (var i1 = 0, l1 = tags.length; i1 < l1; i1++) {
                    autoMethodName = tags[i1] + this.muOpts.autoMethodNameSeparator + eventName;
                    if (autoMethodName in this) {
                        this.muAddEvent(eventName, eventTarget, this.muGetMethodCallback(autoMethodName));
                    }
                }
            }
        }
        // init
        if (tags) {
            const eventTarget = element;
            for (var i = 0, l = tags.length; i < l; i++) {
                autoMethodName = tags[i] + this.muOpts.autoMethodNameSeparator + "init";
                if (autoMethodName in this) {
                    this.muGetMethodCallback(autoMethodName)(eventTarget);
                }
            }
        }
    }
    muFindMethod(name, context) {
        var obj = context || this;
        //todo: blbne
        while (name.startsWith("parent.")) {
            if (!obj.muParent)
                throw "Widget of class '" + obj.constructor.name + "' has not parent.";
            obj = obj.muParent;
            name = name.substr(7);
        }
        var params = [];
        var p = null;
        if (-1 != (p = name.indexOf(':'))) {
            const jsrc = '[' + name.substr(p + 1) + ']';
            params = MuWidget.paramJsonS
                ? JSONS_1.JSONS.parse(jsrc)
                : JSON.parse(jsrc);
            name = name.substr(0, p);
        }
        var method = obj[name];
        if (method === undefined)
            throw "Undefined method '" + name + "' in class '" + obj.constructor.name + "'.";
        return {
            method: method,
            args: params,
            context: obj
        };
    }
    muGetMethodCallback(name, context = null) {
        const methodInfo = this.muFindMethod(name, context);
        return (ev /* , event : Event */) => {
            const callparams = [ev, ...methodInfo.args, ...(ev.args || [])];
            return methodInfo.method.apply(methodInfo.context, callparams);
        };
    }
    muAddEvent(eventName, element, callback) {
        element.addEventListener(eventName, callback);
        /* (element as any).addEventListener(eventName, (/*ev : Event* /) => {
            // return callback(this, ev);
            // return callback.apply(null, arguments);
            console.log("muAddEvent:wrapper", arguments);
            return callback(...Array.from(arguments));
            // return callback.apply(null, [this].concat(Array.from(arguments)));
        }); */
    }
    muGetRoot() {
        //@ts-ignore
        return this.muParent ? this.muParent.muGetRoot() : this;
    }
    muDebugGetClasses() {
        const uniqueClasses = new Set();
        this.container.querySelectorAll('[class]').forEach(function (element) {
            element.classList.forEach(function (className) {
                uniqueClasses.add(className);
            });
        });
        return Array.from(uniqueClasses);
    }
    muReplaceContent(element, newContent) {
        let currentElement = (typeof element === "string")
            ? this.ui[element]
            : element;
        if (!currentElement)
            throw new Error((typeof element === "string")
                ? "Element with mu-id='" + element + "' not exists."
                : 'Argument element is empty');
        currentElement.innerHTML = newContent;
        this.muIndexTree(currentElement, true, null, false);
    }
    static getChildWidgets(container) {
        const ls = [];
        for (const item of container.children) {
            if (item.widget)
                ls.push(item.widget);
        }
        return ls;
    }
    static registerAll(...args) {
        var _a;
        let _;
        for (let i = 0; i < args.length; i++) {
            let classes = args[i];
            if (((_a = classes.default) === null || _a === void 0 ? void 0 : _a.__esModule) === true) {
                const _b = classes.default, { __esModule } = _b, newClasses = __rest(_b, ["__esModule"]);
                classes = newClasses;
            }
            for (let k in classes)
                MuWidget.widgetClasses[k] = classes[k];
        }
    }
    static registerAs(c, n) {
        MuWidget.widgetClasses[n] = c;
    }
    static startup(startElement = null, onSucces = null, onBefore = null, onEvent = "load") {
        const fn = window.addEventListener || window.attachEvent || function (type, listener) {
            if (window.onload) {
                const curronload = window.onload;
                const newonload = function (evt) {
                    curronload(evt);
                    listener(evt);
                };
                window.onload = newonload;
            }
            else {
                window.onload = listener;
            }
        };
        const onStart = function () {
            if (onBefore)
                onBefore();
            var element = (startElement || document.documentElement);
            var muRoot = new MuWidget(element);
            muRoot.muInit(element);
            MuWidget.root = muRoot;
            if (onSucces)
                onSucces(muRoot);
        };
        if (onEvent === "DOMContentLoaded" && ['interactive', 'complete'].includes(document.readyState)
            || onEvent === "load" && 'complete' == document.readyState) {
            onStart();
        }
        else {
            fn(onEvent, onStart);
        }
    }
    static getWidget(el) {
        while (el) {
            if (el.widget)
                return el.widget;
            //@ts-ignore
            el = el.parentElement;
        }
        return null;
    }
}
exports.MuWidget = MuWidget;
MuWidget.fixOldWidgets = false;
MuWidget.sharedTemplates = {};
MuWidget.paramJsonS = true;
MuWidget.identifierRe = '[a-zA-Z_.][0-9a-zA-Z_.]*';
// statics
MuWidget.widgetClasses = {};
MuWidget.plugIns = [];
MuWidget.eventLegacy = false;
function SetAttributes(element, attrs) {
    for (const n in attrs) {
        element.setAttribute(n, attrs[n].toString());
    }
}
exports.SetAttributes = SetAttributes;
// export class MuWidget extends MuWidgetTyped<MuWidget, {}, {}> { }
