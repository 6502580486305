import { MuWidget } from 'mu-widget/lib/MuWidget';

class UiPage extends MuWidget
{
	menu_click() {
		this.ui.menuToggler.checked = false;
	}
}

MuWidget.registerAs(UiPage, 'UiPage')
MuWidget.startup();