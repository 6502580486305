"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MuBinder = void 0;
const StrParser_1 = require("mu-js-utils/lib/StrParser");
const JSONS_1 = require("./utils/JSONS");
class MuBinder {
    static parse(src, element) {
        /*
        source
        source:target
        source::target
        source^target
        sourcer|filter():target
        */
        let mode = "source";
        const optsList = [];
        const sp = new StrParser_1.StrParser(src);
        let p;
        let lastP = 0;
        function parseFetchBind(chunk, opts) {
            switch (chunk) {
                case ":":
                case "::":
                case "^":
                    opts.forBind = chunk != "^";
                    opts.forFetch = chunk != ":";
                    return true;
                case ";":
                    mode = "complete";
                    opts.forBind = true;
                    opts.forFetch = false;
                    return true;
                default:
                    return false;
            }
        }
        function parseFilter(sp, bindPart, opts) {
            p = sp.findNext(["::", ":", "|", "^", ";", "("]);
            let filter = {
                methodName: sp.substring(lastP, p).trim(),
                args: []
            };
            if (!p) {
                mode = "end";
            }
            else if (p.chunk === ";") {
                mode = "complete";
                sp.toEndChunk();
            }
            else {
                sp.toEndChunk();
                lastP = sp.position;
                if (p.chunk === "(") {
                    const argStart = sp.pos();
                    while (true) {
                        p = sp.findNext([")", "\""]);
                        if (p === null)
                            throw "missing ')' after argument(s) '" + src + "'";
                        if (p.chunk == ")") {
                            sp.toEndChunk();
                            lastP = sp.position;
                            break;
                        }
                        // skoč na konec stringu
                        sp.toEndChunk();
                        do {
                            p = sp.findNext(["\\\"", "\""]);
                            if (p === null)
                                throw "unterminated string '" + src + "'";
                            sp.toEndChunk();
                        } while (p.chunk === "\\\"");
                    }
                    const sArgs = sp.substring(argStart, p);
                    try {
                        filter.args = MuBinder.useJsonS
                            ? JSONS_1.JSONS.parse("[" + sArgs + "]")
                            : JSON.parse("[" + sArgs + "]");
                    }
                    catch (exc) {
                        throw "Invalid arguments - " + exc.toString() + " '" + sArgs + "'";
                    }
                }
                mode = parseFetchBind(p.chunk, opts) ? "target" : (bindPart ? "bindFilter" : "fetchFilter");
                // sp.toEndChunk();
                lastP = sp.position;
            }
            return filter.methodName ? filter : null;
        }
        //@ts-ignore
        while (!sp.isEnd() && mode != "end") {
            mode = "source";
            const opts = {
                element: element,
                source: null,
                target: null,
                bindFilters: [],
                fetchFilters: [],
                forBind: null,
                forFetch: null
            };
            // sp.debugMode = true;
            while (mode != "complete" && mode != "end") {
                switch (mode) {
                    case "source":
                        p = sp.findNext(["::", ":", "|", ";", "^"]);
                        opts.source = sp.substring(lastP, p).trim();
                        if (!p) {
                            mode = "end";
                        }
                        else if (p.chunk == ";") {
                            mode = "complete";
                        }
                        else {
                            mode = parseFetchBind(p.chunk, opts) ? "target" : "bindFilter";
                            sp.toEndChunk();
                            lastP = sp.position;
                        }
                        break;
                    case "target":
                        p = sp.findNext(["|", ";"]);
                        opts.target = sp.substring(lastP, p).trim();
                        if (!p) {
                            mode = "end";
                        }
                        else if (p.chunk == ";") {
                            mode = "complete";
                        }
                        else {
                            mode = "fetchFilter";
                            sp.toEndChunk();
                            lastP = sp.position;
                        }
                        break;
                    case "bindFilter":
                        var f = parseFilter(sp, true, opts);
                        if (f)
                            opts.bindFilters.push(f);
                        break;
                    case "fetchFilter":
                        f = parseFilter(sp, false, opts);
                        if (f)
                            opts.fetchFilters.push(f);
                        break;
                }
            }
            optsList.push(opts);
            sp.toEndChunk();
            lastP = sp.position;
        }
        return optsList;
    }
    static setDefaults(mbo) {
        const defaults = {};
        // mbo.element.hasAttribute("mu-widget")
        if (mbo.element.hasAttribute("mu-widget")) {
            defaults.forBind = true;
            defaults.forFetch = true;
            defaults.target = "@widget";
        }
        else if (mbo.element instanceof HTMLInputElement || mbo.element instanceof HTMLTextAreaElement || mbo.element instanceof HTMLSelectElement) {
            if (mbo.element.type === "file") {
                defaults.forBind = false;
                defaults.forFetch = true;
                defaults.target = "files";
            }
            else {
                defaults.forBind = true;
                defaults.forFetch = true;
                if (mbo.element.type === "checkbox")
                    defaults.target = "checked";
                else
                    defaults.target = "value";
            }
        }
        else if (mbo.element instanceof HTMLImageElement || mbo.element instanceof HTMLAudioElement || mbo.element instanceof HTMLVideoElement) {
            defaults.forBind = true;
            defaults.forFetch = false;
            defaults.target = "src";
        }
        else {
            defaults.forBind = true;
            defaults.forFetch = false;
            defaults.target = "text";
        }
        for (const k in defaults) {
            if (mbo[k] === null)
                mbo[k] = defaults[k];
        }
        const targetAlias = {
            text: "innerText",
            html: "innerHTML"
        };
        if (targetAlias[mbo.target])
            mbo.target = targetAlias[mbo.target];
    }
    static beforeIndexElement(ev) {
        if (ev.opts.bind) {
            const bindSrc = ev.opts.bind;
            for (var mbo of MuBinder.parse(bindSrc, ev.element)) {
                MuBinder.setDefaults(mbo);
                if (!ev.widget.muBindOpts)
                    ev.widget.muBindOpts = {};
                if (!ev.widget.muBindOpts[mbo.source])
                    ev.widget.muBindOpts[mbo.source] = [];
                ev.widget.muBindOpts[mbo.source].push(mbo);
            }
        }
    }
    static register(muWidget) {
        // @ts-ignore
        muWidget.plugIns.push({
            beforeIndexElement: MuBinder.beforeIndexElement
        });
    }
    static bindData(bindOpts, srcData, widget) {
        //todo: . stack overflow
        if (srcData === null)
            return;
        let bindedWidget = false;
        let bindedWidgetParam = false;
        for (const k of [/*'.',*/ ...Object.keys(srcData)]) {
            if (bindOpts[k]) {
                for (const mbo of bindOpts[k]) {
                    if (mbo.forBind) {
                        let val = MuBinder.UseFilters(k === "." ? srcData : srcData[k], mbo.bindFilters, widget, { dataset: srcData });
                        if (k === '@widget')
                            bindedWidget = true;
                        else if (k[0] === '.')
                            bindedWidgetParam = true;
                        MuBinder.setValue(val, mbo.target, mbo.element, widget);
                    }
                }
            }
        }
    }
    static fetchData(bindOpts, widget) {
        let resData = {};
        //todo: . stack overflow
        for (const k of [/*'.',*/ ...Object.keys(bindOpts)]) {
            if (bindOpts[k]) {
                for (const mbo of bindOpts[k]) {
                    if (mbo.forFetch) {
                        /* resData[k] = mbo.element[mbo.target];
                        let val = MuBinder.UseFilters(srcData[k], mbo.bindFilters, widget);
                        ; */
                        const values = MuBinder.UseFilters(MuBinder.GetValue(mbo.target, mbo.element, widget), mbo.fetchFilters, widget, { originalValue: resData[k], dataset: resData });
                        if (k === '.')
                            resData = Object.assign({ resData }, values);
                        else
                            resData[k] = values;
                    }
                }
            }
        }
        return resData;
    }
    static UseFilters(val, filters, widget, ev) {
        var _a, _b;
        for (const filter of filters) {
            let obj = null;
            let fn;
            if (filter.methodName in widget)
                obj = widget; // fn = <MuBindFilterCallback>widget[filter.methodName];
            else if (widget.muParent && filter.methodName in widget.muParent)
                obj = widget.muParent; // fn = <MuBindFilterCallback>widget.muParent[filter.methodName];
            else if (filter.methodName in MuBinder.filters)
                obj = MuBinder.filters; //fn = MuBinder.filters[filter.methodName];
            else {
                // Parent widgets
                let w = (_a = widget.muParent) === null || _a === void 0 ? void 0 : _a.muParent;
                while (w) {
                    if (filter.methodName in w) {
                        obj = w;
                        break;
                    }
                    w = w.muParent;
                }
            }
            if (!obj)
                throw new Error("Unknown filter '" + filter.methodName + "'. Source widget: '" + ((_b = widget === null || widget === void 0 ? void 0 : widget.costructor) === null || _b === void 0 ? void 0 : _b.name) + "'");
            fn = obj[filter.methodName];
            val = fn.call(obj, val, Object.assign({}, ev), ...filter.args);
        }
        return val;
    }
    static setValue(val, target, element, widget) {
        if (target === "@widget") {
            element["widget"].muBindData(val);
        }
        else if (target === "foreach" || target === "@foreach") {
            element.innerHTML = "";
            for (const k in widget.muTemplateParents) {
                if (element === widget.muTemplateParents[k]) {
                    let arr = [];
                    if (!Array.isArray(val)) {
                        for (const k in val) {
                            arr.push({
                                key: k,
                                value: val[k]
                            });
                        }
                    }
                    else
                        arr = val;
                    for (const data of arr) {
                        const widgetParams = {};
                        for (const k in data) {
                            if (k.startsWith("."))
                                widgetParams[k.substr(1)] = data[k];
                        }
                        const itemWidget = widget.muWidgetFromTemplate(k, element, widgetParams);
                        itemWidget.muBindData(data);
                        if ("AferBind" in itemWidget) { // @ts-ignore
                            itemWidget.AferBind();
                        }
                    }
                    break;
                }
            }
        }
        else if (target.startsWith("."))
            this.setDeep(val, element["widget"], target.substr(1));
        else if (target.startsWith("@attr."))
            element.setAttribute(target.substr(6), val);
        else if (target == "@visible")
            element.style.display = val ? "" : "none";
        else if (target == "@options") {
            const addOpt = function (val, text) {
                const opt = document.createElement("option");
                opt.text = text;
                opt.value = val;
                if (element instanceof HTMLSelectElement)
                    element.add(opt);
                else {
                    const optEl = document.createElement('option');
                    optEl.value = opt.value;
                    optEl.innerText = opt.text;
                    element.append(optEl);
                }
            };
            element.innerHTML = "";
            if (Array.isArray(val)) {
                for (const item of val) {
                    if (typeof item === "string")
                        addOpt(item, item);
                    else
                        addOpt(item.value, item.text);
                }
            }
            else if (typeof val === "object") {
                for (const v in val) {
                    addOpt(v, val[v]);
                }
            }
        }
        else
            this.setDeep(val, element, target); // element[target] = val;
    }
    static setDeep(value, object, path) {
        let obj = object;
        const fields = path.split(".");
        const lastI = fields.length - 1;
        let i = 0;
        for (const f of fields) {
            if (i < lastI)
                obj = obj[f];
            else
                obj[f] = value;
            if (!obj)
                throw "Can not set value to path'" + path + "'";
            i++;
        }
    }
    static getDeep(object, path) {
        let obj = object;
        const fields = path.split(".");
        for (const f of fields) {
            if (!(f in obj))
                return undefined;
            obj = obj[f];
        }
        return obj;
    }
    static GetValue(target, element, widget) {
        switch (target) {
            case "@widget":
                return element.widget == widget
                    ? null
                    : element["widget"].muFetchData();
            case "foreach":
            case "@foreach":
                return widget.muGetChildWidgets(element).map(itemWidget => itemWidget.muFetchData());
            default:
                if (target.startsWith("."))
                    return this.getDeep(element["widget"], target.substr(1));
                else if (target.startsWith("@attr."))
                    return element.getAttribute(target.substr(6));
                else if (target == "@visible")
                    return element.style.display != "none";
                else
                    return this.getDeep(element, target); // element[target] = val;
                break;
            // return element[target];
        }
    }
}
exports.MuBinder = MuBinder;
MuBinder.useJsonS = true;
MuBinder.filters = {
    toLower: val => {
        return val === null || val === void 0 ? void 0 : val.toString().toLocaleLowerCase();
    },
    toUpper: val => val === null || val === void 0 ? void 0 : val.toString().toLocaleUpperCase(),
    short: (val, ev, maxLen, sufix = "...") => {
        let str = val.toString();
        if (str.length >= maxLen - sufix.length)
            str = str.substr(0, maxLen) + sufix;
        return str;
    },
    tern: (val, ev, onTrue, onFalse) => val ? onTrue : onFalse,
    prepend: (val, ev, prefix, ifAny = false) => !ifAny || val ? prefix + val : val,
    append: (val, ev, prefix, ifAny = false) => !ifAny || val ? val + prefix : val,
    map: (val, ev, map) => map[val],
    // toggleClass: (val, ev, trueClass : string, falseClass : string) =>
    getField: (val, ev, field) => (val !== null && val !== void 0 ? val : {})[field],
    ifEmpty: (val, ev, newValue) => val || newValue,
    ifNull: (val, ev, newValue) => val !== null && val !== void 0 ? val : newValue,
};
